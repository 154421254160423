import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Linkify from "react-linkify";
import NcImage from 'shared/NcImage/NcImage';

interface FAQAccordionShortItem {
  id: string;
  question: string;
  answer: string;
}

const accordionItems: FAQAccordionShortItem[] = [
  {
    id: 'hs-basic-with-title-and-arrow-stretched-heading-one',
    question: "What is GetCollabo?",
    answer: "GetCollabo is an all-in-one invoicing, payments, contracts, communication, and collaboration platform for independent creators.",
  },
  {
    id: 'hs-basic-with-title-and-arrow-stretched-heading-two',
    question: "What does it cost to use GetCollabo?",
    answer: "We have two payment options available: Basic and Pro. The Basic plan starts at $10/month and the Pro plan starts at $25/month. Both options include 2+ customizable rate card templates, unlimited invoicing, and automated content tracking & reporting. However, with the Pro plan, you can upload and deliver up to 10GB of content to clients or brands.",
  },
  {
    id: 'hs-basic-with-title-and-arrow-stretched-heading-three',
    question: "Do you offer free trials?",
    answer: "We have a Forever Free Plan that allows you to customize a professional rate card, send unlimited invoices, manage multiple projects and see if GetCollabo works for you. We recommend signing up for free and upgrading when you're ready.",
  },
  {
    id: 'hs-basic-with-title-and-arrow-stretched-heading-five',
    question: 'Can I upgrade or downgrade my plan?',
    answer: 'Certainly! You can easily handle your subscription, change your plan to monthly or yearly, or downgrade your subscription.',
  },
  {
    id: 'hs-basic-with-title-and-arrow-stretched-heading-six',
    question: 'Can I cancel my subscription at any time?',
    answer: 'Of course! You have the freedom to cancel your subscription at any time within the billing settings. If you choose to cancel before the subscription period ends, you will still retain access to the service until that period concludes.',
  },
];

const FAQAccordionShort: React.FC = () => {
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);

  const toggleAccordion = (id: string) => {
    setActiveAccordion((prev) => (prev === id ? null : id));
  };

  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div className="grid gap-10 md:grid-cols-5">
        <div className="md:col-span-2">
          <div className="max-w-xs">
            <h2 className="text-4xl font-bold sm:text-5xl md:text-5xl xl:text-5xl lg:text-4xl md:leading-tight dark:text-white">FAQs</h2>
            <p className="mt-1 text-gray-600 md:block dark:text-gray-400">Answers to some of our most frequently asked questions.</p>
          </div>
          <NcImage className="w-100 h-100" src="https://img.icons8.com/plasticine/100/faq.png" alt="faq" />
        </div>

        <div className="md:col-span-3">
          <div className="divide-y divide-gray-200 hs-accordion-group dark:divide-gray-700">
            {accordionItems.map((item) => (
              <div className={`hs-accordion pt-6 pb-3 ${activeAccordion === item.id ? 'active' : ''}`} key={item.id}>
                <button
                  className="inline-flex items-center justify-between w-full pb-3 font-medium text-left text-gray-800 transition hs-accordion-toggle group gap-x-3 md:text-lg hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400"
                  onClick={() => toggleAccordion(item.id)}
                  aria-controls={`hs-${item.id}-collapse`}
                  aria-expanded={activeAccordion === item.id}
                >
                  <span className={`hs-accordion-title ${activeAccordion === item.id ? 'text-primary-6000 group-hover:text-primary-6000 dark:text-primary-6000' : 'group-hover:text-gray-500'}`}>{item.question}</span>
                  <svg
                    className={`hs-accordion-active ${activeAccordion === item.id ? 'hidden' : 'block'} w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400`}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                  <svg
                    className={`hs-accordion-active ${activeAccordion === item.id ? 'block' : 'hidden'} w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400`}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                </button>
                <div
                  id={`hs-${item.id}-collapse`}
                  className={`hs-accordion-content ${activeAccordion === item.id ? 'block' : 'hidden'} w-full overflow-hidden transition-[height] duration-300`}
                >
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    <Linkify
                      componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a
                          href={decoratedHref}
                          key={key}
                          className="font-semibold underline text-primary-6000"
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      {item.answer}
                    </Linkify>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-8 mb-4">
            <span className="block text-center xl:text-end md:text-end text-neutral-700 dark:text-neutral-300">
              For our full FAQs, {` `}
              <Link
                className="underline text-primary-6000 hover:no-underline"
                to="/faqs"
              >
                Click here
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQAccordionShort;