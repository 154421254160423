import React, { useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import ShareUs from "images/shareCollabo.png";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FormItem from "components/FormItem";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const Share: React.FC = () => {
  const { searchName } = useParams<{ searchName: string }>();

  const [copy, setCopy] = useState(false);

  const userName = searchName
    ? searchName.charAt(0).toUpperCase() + searchName.slice(1).toLowerCase()
    : null;

  return (
    <div className="nc-Share">
      <Helmet>
        <title>Share GetCollabo</title>
      </Helmet>
      <div className="container flex justify-center max-w-3xl mt-10 mb-10 text-center sm:max-w-xl lg:max-w-3xl xl:max-w-3xl md:max-w-xl">
        {/* HEADER */}
        <section>
          <span className="text-base text-neutral-800 dark:text-neutral-200">
            We couldn't find{" "}
            <span className="font-semibold text-primary-6000">
              "{userName}"
            </span>{" "}
            on GetCollabo. How about you send them a link to signup - IT'S
            FREE🎉
          </span>
          <div className="mt-10 mb-4">
            <FormItem label={`${userName}'s invite link:`}>
              <div className="relative">
                <CopyToClipboard
                  text={`https://app.getcollabo.io/welcome/${searchName}`}
                  onCopy={() => {
                    setCopy(true);
                  }}
                >
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 z-10 flex items-center px-3 text-white border-2 bg-primary-6000 dark:bg-primary-6000 border-primary-6000 rounded-2xl focus:outline-none"
                  >
                    {!copy ? (
                      <>
                        <svg
                          className="w-6 h-6 mr-1"
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <path
                            d="M18.05 9.19992L17.2333 12.6833C16.5333 15.6916 15.15 16.9083 12.55 16.6583C12.1333 16.6249 11.6833 16.5499 11.2 16.4333L9.79999 16.0999C6.32499 15.2749 5.24999 13.5583 6.06665 10.0749L6.88332 6.58326C7.04999 5.87492 7.24999 5.25826 7.49999 4.74992C8.47499 2.73326 10.1333 2.19159 12.9167 2.84993L14.3083 3.17493C17.8 3.99159 18.8667 5.71659 18.05 9.19992Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.5498 16.6583C12.0331 17.0083 11.3831 17.3 10.5915 17.5583L9.2748 17.9917C5.96646 19.0583 4.2248 18.1667 3.1498 14.8583L2.08313 11.5667C1.01646 8.25833 1.8998 6.50833 5.20813 5.44167L6.5248 5.00833C6.86646 4.9 7.19146 4.80833 7.4998 4.75C7.2498 5.25833 7.0498 5.875 6.88313 6.58333L6.06646 10.075C5.2498 13.5583 6.3248 15.275 9.7998 16.1L11.1998 16.4333C11.6831 16.55 12.1331 16.625 12.5498 16.6583Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p title="Copy booking link">Copy</p>
                      </>
                    ) : (
                      <>
                        <svg
                          className="w-6 h-6 mr-1"
                          viewBox="0 0 20 21"
                          fill="currentColor"
                        >
                          <path
                            d="M18.05 9.19992L17.2333 12.6833C16.5333 15.6916 15.15 16.9083 12.55 16.6583C12.1333 16.6249 11.6833 16.5499 11.2 16.4333L9.79999 16.0999C6.32499 15.2749 5.24999 13.5583 6.06665 10.0749L6.88332 6.58326C7.04999 5.87492 7.24999 5.25826 7.49999 4.74992C8.47499 2.73326 10.1333 2.19159 12.9167 2.84993L14.3083 3.17493C17.8 3.99159 18.8667 5.71659 18.05 9.19992Z"
                            stroke="#0483c3"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.5498 16.6583C12.0331 17.0083 11.3831 17.3 10.5915 17.5583L9.2748 17.9917C5.96646 19.0583 4.2248 18.1667 3.1498 14.8583L2.08313 11.5667C1.01646 8.25833 1.8998 6.50833 5.20813 5.44167L6.5248 5.00833C6.86646 4.9 7.19146 4.80833 7.4998 4.75C7.2498 5.25833 7.0498 5.875 6.88313 6.58333L6.06646 10.075C5.2498 13.5583 6.3248 15.275 9.7998 16.1L11.1998 16.4333C11.6831 16.55 12.1331 16.625 12.5498 16.6583Z"
                            stroke="#0483c3"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p title="Link copied">Link copied</p>
                      </>
                    )}
                  </button>
                </CopyToClipboard>
                <Input
                  type="text"
                  disabled
                  className="w-full pr-10 mb-2 bg-transparent placeholder:text-black dark:placeholder:text-white"
                  placeholder={`app.getcollabo.io/welcome/${searchName}`}
                />
              </div>
            </FormItem>
          </div>

          <div className="justify-center pt-4 pl-8 pr-8 text-center">
            <p className="mb-6 text-base text-neutral-500 dark:text-neutral-400">
              Are you{" "}
              <span className="font-semibold text-primary-6000">
                "{userName}"
              </span>
              ? Create a free account.
            </p>

            <a href={`https://app.getcollabo.io/welcome/${searchName}`}  target="_blank">
              <ButtonPrimary>
                <span>Signup</span>
                <svg
                  className="w-6 h-6 ml-2 animate-bounce"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 22H21"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonPrimary>
            </a>
          </div>
          <NcImage className="mt-10" src={ShareUs} />
        </section>
      </div>
    </div>
  );
};

export default Share;