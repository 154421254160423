// @ts-nocheck
import React, { FC, useState } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Helmet } from "react-helmet";
import Nav from "shared/Nav/Nav";
import NavItem2 from "components/NavItem2";
import { useHistory } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import FAQAccordionShort from "containers/FaqAccordion/FAQAccordionShort";

export interface PricingProps {
  className?: string;
}

export interface PricingItem {
  isPopular: boolean;
  name: string;
  id: string;
  pricing: number;
  desc: string;
  per: string;
  features: string[];
}

const Pricing: FC<PricingProps> = ({ className = "" }) => {
  const [tabActive, setTabActive] = React.useState("Monthly");

  const [showMonthly, setShowMonthly] = useState(true);
  const [showYearly, setShowYearly] = useState(false);

  const handleUserSelection = (userType: string) => {
    if (userType === "monthly") {
      setShowMonthly(true);
      setShowYearly(false);
    } else if (userType === "yearly") {
      setShowMonthly(false);
      setShowYearly(true);
    }
  };

  const pricingList: PricingItem[] = [
    {
      isPopular: false,
      name: "Free",
      pricing: "$0",
      per: "/month",
      features: [
        "1 rate card template",
        "3 projects",
        "3 contracts",
        "3 questionnaires",
        "Unlimited invoices",
        "Unlimited client/brand contacts",
        "200MB content upload",
        "Commission-fee on payments",
      ],
      desc: "For creators just starting out with brand collaborations and want just a few tools",
    },
    {
      isPopular: true,
      name: "Basic Plan",
      pricing: "$10",
      per: "/month",
      features: [
        "3 rate card templates",
        "50 projects",
        "50 contracts",
        "50 questionnaires",
        "Omnichannel messaging",
        "Unlimited invoices",
        "Unlimited client/brand contacts",
        "Automated content reporting",
        "1GB content upload",
        "No commission-fee on payments",
        "No GetCollabo branding",
        "SEO support for rate cards",
      ],
      desc: "For growing creators looking to streamline their collaborations like pros",
    },
    {
      isPopular: false,
      name: "Pro Plan",
      pricing: "$25",
      per: "/month",
      features: [
        "5 rate card templates",
        "Unlimited projects",
        "Unlimited contracts",
        "Unlimited questionnaires",
        "Automated content reporting",
        "Omnichannel messaging",
        "Unlimited client/brand contacts",
        "Unlimited invoices",
        "10GB content upload",
        "No commission-fee on payments",
        "No GetCollabo branding",
        "Boosted SEO support for rate cards",
      ],
      desc: "For bigger creators looking to maximize their brand collaborations like business owners",
    },
    {
      isPopular: false,
      name: "Free",
      pricing: "$0",
      per: "/year",
      features: [
        "1 rate card template",
        "3 projects",
        "3 contracts",
        "3 questionnaires",
        "Unlimited invoices",
        "Unlimited client/brand contacts",
        "200MB content upload",
        "Commission-fee on payments",
      ],
      desc: "For creators just starting out with brand collaborations and want just a few tools",
    },
    {
      isPopular: true,
      name: "Basic Plan",
      pricing: "$60",
      per: "/year",
      features: [
        "3 rate card templates",
        "50 projects",
        "50 contracts",
        "50 questionnaires",
        "Omnichannel messaging",
        "Unlimited invoices",
        "Unlimited client/brand contacts",
        "Automated content reporting",
        "1GB content upload",
        "No commission-fee on payments",
        "No GetCollabo branding",
        "SEO support for rate cards",
      ],
      desc: "For growing creators looking to streamline their collaborations like pros",
    },
    {
      isPopular: false,
      name: "Pro Plan",
      pricing: "$180",
      per: "/year",
      features: [
        "5 rate card templates",
        "Unlimited projects",
        "Unlimited contracts",
        "Unlimited questionnaires",
        "Automated content reporting",
        "Omnichannel messaging",
        "Unlimited client/brand contacts",
        "Unlimited invoices",
        "10GB content upload",
        "No commission-fee on payments",
        "No GetCollabo branding",
        "Boosted SEO support for rate cards",
      ],
      desc: "For bigger creators looking to maximize their brand collaborations like business owners",
    },
  ];

  const openInNewTab = (url) => {
    const newTab = window.open(url, "_blank");
    if (newTab) {
      newTab.focus();
    }
  };

  const signup = () => {
    openInNewTab("https://app.getcollabo.io/signup");
  };

  const renderPricingItem = (pricing: PricingItem, index: number) => {
    return (
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${
          pricing.isPopular
            ? "border-primary-500"
            : "border-neutral-100 dark:border-neutral-700"
        }`}
      >
        {pricing.isPopular && (
          <span className="absolute z-10 px-3 py-1 text-xs tracking-widest text-white rounded-full bg-primary-500 right-3 top-3">
            Most popular
          </span>
        )}

        <div className="mb-2">
          <h3 className="block mb-2 text-base font-medium tracking-widest uppercase text-neutral-6000 dark:text-neutral-300">
            {pricing.name}
          </h3>
          <h2 className="flex items-center text-4xl leading-none font">
            <span className="font-semibold text-black dark:text-white">{pricing.pricing}</span>
            <span className="ml-1 text-base font-normal text-neutral-500">
              {pricing.per}
            </span>
          </h2>
        </div>
        {pricing.features.slice(0, 5).map((item, index) => (
          <div
            key={index}
            className="px-4 pt-4 text-sm text-neutral-500 dark:text-neutral-400"
          >
            <li className="flex items-center">
              <span className="inline-flex flex-shrink-0 mr-4 text-primary-6000">
                <CheckIcon className="w-5 h-5" aria-hidden="true" />
              </span>
              <span
                className={`text-sm ${
                  item.includes("Everything in")
                    ? "text-primary-6000 font-semibold"
                    : "text-neutral-700 dark:text-neutral-300"
                }`}
              >
                {item}
              </span>
            </li>
          </div>
        ))}

        <Disclosure defaultOpen={false}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 mt-5 text-base font-medium text-left rounded-lg bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                <span className="text-black dark:text-white">
                  More features
                </span>
                <ChevronDownIcon
                  className={`${
                    open ? "transform rotate-180" : ""
                  } w-5 h-5 text-black dark:text-white`}
                />
              </Disclosure.Button>

              {pricing.features.slice(5, 12).map((item, index) => (
                <Disclosure.Panel
                  key={index}
                  className="px-4 pt-4 text-sm text-neutral-500 dark:text-neutral-400"
                  as="p"
                >
                  <li className="flex items-center">
                    <span className="inline-flex flex-shrink-0 mr-4 text-primary-6000">
                      <CheckIcon className="w-5 h-5" aria-hidden="true" />
                    </span>
                    <span
                      className={`text-sm ${
                        item.includes("Everything in")
                          ? "text-primary-6000 font-semibold"
                          : "text-neutral-700 dark:text-neutral-300"
                      }`}
                    >
                      {item}
                    </span>
                  </li>
                </Disclosure.Panel>
              ))}
            </>
          )}
        </Disclosure>

        <div className="flex flex-col mt-8">
          {pricing.name === "Basic Plan" ? (
            <ButtonPrimary onClick={signup}>Get started</ButtonPrimary>
          ) : (
            <ButtonSecondary onClick={signup}>Get started</ButtonSecondary>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-Pricing container pb-24 lg:pb-32 ${className}`}
      data-nc-id="Pricing"
    >
      <Helmet>
        <title>Pricing - GetCollabo</title>

        {/*-- Open Graph / Facebook --*/}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://getcollabo.io" />
        <meta property="og:title" content="GetCollabo" />
        <meta
          property="og:description"
          content="Build your rate card, send invoices, collect payments, sign contracts, track all your messages, and manage multiple collabs - all in one place."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/newlink/image/upload/v1686527321/samples/Screenshot_159_igtnfd.png"
        />

        {/*-- Twitter --*/}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://getcollabo.io" />
        <meta name="twitter:title" content="GetCollabo" />
        <meta
          name="twitter:description"
          content="Build your rate card, send invoices, collect payments, sign contracts, track all your messages, and manage multiple collabs - all in one place."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/newlink/image/upload/v1686527321/samples/Screenshot_159_igtnfd.png"
        />
      </Helmet>

      <header className="items-center justify-center max-w-2xl mx-auto mt-10 mb-6 text-center">
        <h2 className="flex items-center text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Pricing
        </h2>
        <span className="block mt-4 mb-10 text-base text-neutral-700 dark:text-neutral-200">
          Simple pricing for independent creators
        </span>
      </header>

      <Nav
        className="p-1 bg-white rounded-full shadow-lg dark:bg-neutral-800"
        containerClassName="mb-8 relative flex justify-center w-full text-sm md:text-base"
      >
        {[
          {
            name: "Monthly",
            user: "monthly",
          },
          {
            name: "Yearly",
            user: "yearly",
          },
        ].map((item, index) => (
          <NavItem2
            key={index}
            isActive={tabActive === item.name}
            onClick={() => {
              setTabActive(item.name);
              handleUserSelection(item.user);
            }}
          >
            <div className="flex items-center justify-center sm:space-x-2.5 text-sm ">
              <span>{item.name}</span>
            </div>
          </NavItem2>
        ))}
      </Nav>

      <section className="mt-5 overflow-hidden text-sm text-neutral-600 md:text-base">
        <div className="grid gap-5 lg:grid-cols-3 xl:gap-8">
          {pricingList.map((pricing, index) => {
            if (
              (showMonthly && pricing.per === "/month") ||
              (showYearly && pricing.per === "/year")
            ) {
              return renderPricingItem(pricing, index);
            }
            return null;
          })}
        </div>
      </section>


      <div className="relative py-20 lg:py-20">
        <FAQAccordionShort />
      </div>
    </div>
  );
};

export default Pricing;