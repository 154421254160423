import { Popover, Transition } from "@headlessui/react";
import Input from "shared/Input/Input";
import React, { Fragment, useState } from "react";
import newRequest from "utils/newRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AxiosError } from "axios";

const SearchDropdown = () => {
  const inputRef = React.createRef<HTMLInputElement>();

  const [searchValue, setSearchValue] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!searchValue.trim()) {
      return toast.error("😢You need to type in a username", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const useValue = searchValue.toLowerCase();

    try {
      const res = await newRequest(`/influencer/get/${useValue}`);
      const creator = res.data;

      if (creator) {
        window.open(`/${useValue}`, "_blank");
        setSearchValue("");
      } else {
        window.open(`/share/${useValue}`, "_blank");
        setSearchValue("");
      }
    } catch (err) {
      console.error("Error fetching influencer:", err);
      const axiosError = err as AxiosError;

      if (axiosError.response && axiosError.response.status === 404) {
        window.open(`/share/${useValue}`, "_blank");
        setSearchValue("");
      } else {
        console.log(axiosError);
      }
    }
  };

  const handleIconClick = async (e: any) => {
    e.preventDefault();

    if (!searchValue.trim()) {
      return toast.error("😢You need to type in a username", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const useValue = searchValue.toLowerCase();

    try {
      const res = await newRequest(`/influencer/get/${useValue}`);
      const creator = res.data;

      if (creator) {
        window.open(`/${useValue}`, "_blank");
        setSearchValue("");
      } else {
        window.open(`/share/${useValue}`, "_blank");
        setSearchValue("");
      }
    } catch (err) {
      console.error("Error fetching influencer:", err);
      const axiosError = err as AxiosError;

      if (axiosError.response && axiosError.response.status === 404) {
        window.open(`/share/${useValue}`, "_blank");
        setSearchValue("");
      } else {
        console.log(axiosError);
      }
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <React.Fragment>
      <Popover className="relative">
        {({ open }) => {
          if (open) {
            setTimeout(() => {
              inputRef.current?.focus();
            }, 10);
          }

          return (
            <>
              <Popover.Button className="text-2xl md:text-[28px] w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center">
                <svg
                  className="flex w-5 h-5 sm:hidden md:hidden lg:hidden xl:hidden"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Popover.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className="absolute z-10 w-screen max-w-xs mt-3 -right-28"
                >
                  <form onSubmit={handleSubmit}>
                    <Input
                      ref={inputRef}
                      type="search"
                      placeholder="Find your favorite creator"
                      name="search"
                      value={searchValue}
                      onChange={handleChange}
                    />
                    <input type="submit" hidden value="" />
                  </form>
                </Popover.Panel>
              </Transition>

              <ToastContainer className="text-sm" />
            </>
          );
        }}
      </Popover>
    </React.Fragment>
  );
};

export default SearchDropdown;