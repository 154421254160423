// @ts-nocheck
import React, { FC, useState, useEffect, useContext } from "react";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import { Helmet } from "react-helmet";
import TimeCountDown from "./TimeCountDown";
import Reviews from "./Reviews";
import AccordionInfo from "./AccordionInfo";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParams, useHistory } from "react-router-dom";
import { InfluencerData } from "routers/types";
import newRequest from "utils/newRequest";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface InfluencerDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const InfluencerDetailPage: FC<InfluencerDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const { username } = useParams();

  const [influencer, setInfluencer] = useState<InfluencerData>({});

  const [copy, setCopy] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const fetchInfluencer = async () => {
      try {
        const response = await newRequest.get(`/influencer/get/${username}`);
        if (response.data) {
          setInfluencer(response.data);
        }
      } catch (error) {
        if (error.response.data.message) {
          history.push(`/share/${username}`);
        }
      }
    };

    fetchInfluencer();
  }, [username]);

  const openEmailComposer = (email, subject) => {
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    window.location.href = mailtoLink;
  };

  const headerName = influencer?.username
    ? influencer.username.charAt(0).toUpperCase() +
      influencer.username.slice(1).toLowerCase()
    : "Creator";

  return (
    <div className={`nc-NftDetailPage pb-4 lg:pb-10 pt-2 ${className}`} data-nc-id="NftDetailPage">
      
      <Helmet>
        <title>Book {headerName}</title>
        <meta name="title" content={`Book ${headerName}`} />
        <meta
          name="description"
          content={`${headerName} now accepts bookings using GetCollabo`}
        />
        <meta name="image" content={influencer.img} />

        {/*-- Open Graph / Facebook --*/}
        <meta property="og:title" content={`Book ${headerName}`} />
        <meta
          property="og:description"
          content={`${headerName} now accepts bookings using GetCollabo`}
        />
        <meta property="og:url" content="https://getcollabo.io/teetat" />
        <meta property="og:image" content={influencer.img} />

        {/*-- Twitter --*/}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@getcollabo" />
        <meta name="twitter:title" content={`Book ${headerName}`} />
        <meta
          name="twitter:description"
          content={`${headerName} now accepts bookings using GetCollabo`}
        />
        <meta name="twitter:url" content="https://getcollabo.io/teetat" />
        <meta name="twitter:image" content={influencer.img} />
      </Helmet>

      {/* MAIn */}
      <main className="container flex mt-11 ">
        <div className="grid w-full grid-cols-1 gap-10 lg:grid-cols-2 md:gap-14">
          {/* CONTENT */}
          <div className="space-y-8 lg:space-y-10">
            {/* HEADING */}
            <div className="relative">
              <NcImage
                src={influencer?.img}
                className="object-cover"
                containerClassName="aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden"
              />
            </div>

            {influencer && <AccordionInfo dataProp={influencer} />}
          </div>

          {/* SIDEBAR */}
          <div className="pt-10 border-t-2 lg:pt-0 xl:pl-10 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
            <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
              {/* ---------- 1 ----------  */}
              <div className="space-y-5 pb-9">
                <div className="flex items-center justify-between">
                  <Badge name="Active" color="green" />
                  <CopyToClipboard
                    text={`https://getcollabo.io/${influencer?.username}`}
                    onCopy={() => setCopy(true)}
                  >
                    <div className="flex items-center space-x-2 text-sm">
                      {!copy ? (
                        <>
                          <svg
                            className="w-6 h-6"
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <path
                              d="M18.05 9.19992L17.2333 12.6833C16.5333 15.6916 15.15 16.9083 12.55 16.6583C12.1333 16.6249 11.6833 16.5499 11.2 16.4333L9.79999 16.0999C6.32499 15.2749 5.24999 13.5583 6.06665 10.0749L6.88332 6.58326C7.04999 5.87492 7.24999 5.25826 7.49999 4.74992C8.47499 2.73326 10.1333 2.19159 12.9167 2.84993L14.3083 3.17493C17.8 3.99159 18.8667 5.71659 18.05 9.19992Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.5498 16.6583C12.0331 17.0083 11.3831 17.3 10.5915 17.5583L9.2748 17.9917C5.96646 19.0583 4.2248 18.1667 3.1498 14.8583L2.08313 11.5667C1.01646 8.25833 1.8998 6.50833 5.20813 5.44167L6.5248 5.00833C6.86646 4.9 7.19146 4.80833 7.4998 4.75C7.2498 5.25833 7.0498 5.875 6.88313 6.58333L6.06646 10.075C5.2498 13.5583 6.3248 15.275 9.7998 16.1L11.1998 16.4333C11.6831 16.55 12.1331 16.625 12.5498 16.6583Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p title="Copy booking link">Copy rate card link</p>
                        </>
                      ) : (
                        <>
                          <svg
                            className="w-6 h-6"
                            viewBox="0 0 20 21"
                            fill="currentColor"
                          >
                            <path
                              d="M18.05 9.19992L17.2333 12.6833C16.5333 15.6916 15.15 16.9083 12.55 16.6583C12.1333 16.6249 11.6833 16.5499 11.2 16.4333L9.79999 16.0999C6.32499 15.2749 5.24999 13.5583 6.06665 10.0749L6.88332 6.58326C7.04999 5.87492 7.24999 5.25826 7.49999 4.74992C8.47499 2.73326 10.1333 2.19159 12.9167 2.84993L14.3083 3.17493C17.8 3.99159 18.8667 5.71659 18.05 9.19992Z"
                              stroke="#0483c3"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.5498 16.6583C12.0331 17.0083 11.3831 17.3 10.5915 17.5583L9.2748 17.9917C5.96646 19.0583 4.2248 18.1667 3.1498 14.8583L2.08313 11.5667C1.01646 8.25833 1.8998 6.50833 5.20813 5.44167L6.5248 5.00833C6.86646 4.9 7.19146 4.80833 7.4998 4.75C7.2498 5.25833 7.0498 5.875 6.88313 6.58333L6.06646 10.075C5.2498 13.5583 6.3248 15.275 9.7998 16.1L11.1998 16.4333C11.6831 16.55 12.1331 16.625 12.5498 16.6583Z"
                              stroke="#0483c3"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p title="Link copied">Link copied</p>
                        </>
                      )}
                    </div>
                  </CopyToClipboard>
                </div>
                <h2 className="text-2xl font-semibold sm:text-3xl lg:text-4xl">
                  {influencer?.displayName}
                </h2>

                {/* ---------- 4 ----------  */}
                <div className="flex flex-col space-y-4 text-sm sm:flex-row sm:items-center sm:space-y-0 sm:space-x-8">
                  <div className="flex items-center ">
                    <img
                      src={influencer?.img}
                      alt=""
                      className="object-cover w-10 h-10 rounded-full"
                    />
                    <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
                      <span className="flex items-center font-medium capitalize text-neutral-900 dark:text-neutral-200">
                        <span>{influencer?.username}</span>
                        {/**
                         *  <VerifyIcon />
                         */}
                      </span>
                      <span className="mt-1 text-sm capitalize">
                        {influencer?.industry} Creator
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              {/* ---------- 6 ----------  */}
              {influencer && (
                <div className="py-9">
                  <TimeCountDown dataProp={influencer} />
                </div>
              )}

              {/* ---------- 7 ----------  */}
              {/* PRICE */}
              <div className="pt-9 pb-9">
                <div className="flex flex-col sm:flex-row sm:items-end sm:justify-between">
                  <div className="relative flex flex-col items-baseline flex-1 p-6 border-2 border-green-500 sm:flex-row rounded-xl">
                    <span className="absolute bottom-full translate-y-1 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-neutral-500 dark:text-neutral-400">
                      Min. Book Rate
                    </span>
                    {influencer?.deliverable &&
                      influencer?.deliverable.length > 0 && (
                        <span className="text-3xl font-semibold text-green-500 xl:text-4xl">
                          NGN{" "}
                          <span className="ml-1">
                            {influencer.deliverable
                              .reduce(
                                (min, item) =>
                                  item.rate < min ? item.rate : min,
                                Number.MAX_SAFE_INTEGER
                              )
                              .toLocaleString()}
                          </span>
                        </span>
                      )}
                  </div>
                </div>

                <div className="flex flex-col mt-8 space-y-2 sm:flex-row sm:space-y-0 sm:space-x-3">
                  <ButtonPrimary
                    onClick={() =>
                      openEmailComposer(
                        `${influencer?.email}`,
                        "I'd love to collaborate with you"
                      )
                    }
                    className="flex-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                    <span className="ml-2.5">
                      Email {influencer.username || "this creator"}
                    </span>
                  </ButtonPrimary>
                </div>
              </div>

              {/* ---------- 9 ----------  */}
              <div className="pt-9">
                {influencer && <Reviews reviewsProp={influencer} />}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default InfluencerDetailPage;