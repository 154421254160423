import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import NcModal from "shared/NcModal/NcModal";

export interface SectionHero2Props {
  className?: string;
}

const SectionHero2: FC<SectionHero2Props> = ({ className = "" }) => {
  const [showModal, setShowModal] = useState(false);

  const renderContent = () => {
    return (
      <div className="ml-1 mr-1">
        <iframe
          allowFullScreen
          title="How to build a professional creator/influencer rate card in 2023"
          src="https://www.youtube.com/embed/fiUI0koisHY"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          className="relative w-full xl:h-[506px] md:h-[460px] lg:h-[460px] h-[250px] sm:h-[380px] max-w-4xl mx-auto border border-gray-300 shadow-2xl rounded-3xl dark:border-gray-700 yt-video"
        ></iframe>

        <div className="mt-4 space-x-3">
          <ButtonSecondary
            sizeClass="px-5 py-2"
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
              />
            </svg>
            <span>Close</span>
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-SectionHero2 flex flex-col-reverse lg:flex-col relative ${className}`}
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex flex-col items-start flex-shrink-0 space-y-8 lg:w-1/2 sm:space-y-10 pb-14 lg:pb-36 xl:pb-60 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="mt-10 sm:mt-20 lg:mt-14 xl:mt-14 md:mt-20 font-semibold text-4xl sm:text-6xl md:text-6xl xl:text-6xl !leading-[114%]">
            Collaborate <br />
            Like a{" "}
            <span className="relative pr-3">
              <img
                className="absolute w-full bottom-3.5 sm:bottom-5 xl:bottom-6 -left-1"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKIAAAAfCAYAAABkphawAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQLSURBVHgB7ZsNUtNAFMffhlFhdMbewNwAPAHxBPQG1hMYuIBwASbeAE5AOYHhBMYTGE5gnVGRGcn6f5ukLKFtkjYNTft+M7Bpkn7/8j42qSKgfb93QzTgZUU03AmCmAShRdSN77tKqS9YdrN1sdb6ncgotImjifp0LyHjOkp9vfX9PglCSziTVkLOnlbq4vbo6BMJQguoH6gPdxAB6WFUvN9BqbPnSXKogmBEgrAkFP+bUCcWkbpRWComNbNgN1q/Ja2HU/ZjUb9LqhaWhSquQHQ8hnSzhJPoKDTOo2YFgh1DtA9YnFYTSnQUGkdN21ChbmQ4Op5A3jMShBrwSRQe8yZYld2hQqo2nXWSJCeSroVZsHx/iLwtpT7ipmdWKhVsn54elorIVIyOIqQwEfjjbTnOQaL1ADd7xe3cc1QS0XrA0ujIYJ9jCHkuQm4uLJ/jOPuQzKcJ8tlweVdLxOwJODpeYHGvZNcYpwqHd0nyWYTcDCz5BlSSPW1qR8TCkw6y6OiW7Sspe32ZV76MESw82Q6CYG4RrRfC6fo9VReSU3ZIQifhhuMvsuGsmq8Elu9cp5cbhvnKhUVkOF1jyIWsgpn2wRhKlFx9Mvn6CvJBIo/qy8eE+M7PtyHgpOsWGhExZw4hTZSkJLl8EQRDElYGK+V6lE+11CdE1LzcIToru2imURFzLCH3qXrdEGP/UFL30/DL9/dwms1Dg3lAaSM6T9RjKstnsxQRczIhvapNjYWRUiLl8hiLh6i3QLplWLZoHvlsliqijemyid4jF3tUH3OUJRhfBUFEQi3yBsNKtYtEPGaktB7i+7iaVvPVpTURc+ZM2zYxZI40ouUdjkQR8yG5dJo7W6JdnR74Li1OhMh5Vex2m6J1EW24IMbA85FclyyUGnCkX2EMcYRGm3I1OR/USK97OCDdhqVjTNTDY39bJOVW5UlFzBlPDxAdINo18aMtIycK7+hfklzjcaOuCsqyQYbeVppOWbw3+Iw44rm0WHotMq71+PNqu2FcCRGL/Pb9vsNizp++p2E+bHyRIzx+zJLiC46TdH3c5pxmdvD1WLJneI9JKpURTTtOD2kwr+OalM3mScUrspIi2oy7uzRaetQOMT6Ykc4uDkaKivMNEOa67M446/AaNexYIKRMN1ssjm0S432EnGpXselbeRGLZHWlhxe+zymKlhcxusxYuq6UJZ0TsQhHzKx+4vnKXSq/KmidSEsNrTnKcYkRvmQJO1gLd17ESbCcKq23eNylNDV2VVBTvyLCRSgVfuIv4mmrrgo3jbUUcRrZHKZpDDJJOa3vmo2prC61S0zp1SimJs3qT9M8Qbh43WSbxUaJWBVryiSvP11rs1t2f25y1MNfQcb2KFccPeY/fecD/SyCeGwAAAAASUVORK5CYII="
                alt="Vector1"
              />
              <span className="relative">Pro</span>
            </span>
          </h2>

          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            Build your rate card, send invoices, collect payments, sign contracts, track all your messages, and manage multiple collabs - all in one place.
            <br />{" "}
          </span>

          <div className="flex flex-col mt-8 space-y-2 sm:flex-row sm:space-y-0 sm:space-x-3">
            <a href="https://app.getcollabo.io/signup" target="_blank">
              <ButtonPrimary sizeClass="py-3 px-10" className="flex-1">
                <span className="mr-2">Start for free</span>
                <span>
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 22H21"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </ButtonPrimary>
            </a>
            <a href="https://app.getcollabo.io/login" target="_blank">
              <ButtonSecondary sizeClass="py-3 px-16" className="flex-1">
                <span className="mr-2">Login</span>
                <span>
                  <svg
                    className="flex-shrink-0 w-5 h-5 transition duration-75 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                    />
                  </svg>
                </span>
              </ButtonSecondary>
            </a>
          </div>
        </div>
        <div className="flex-grow mb-44">
          <div className="relative bg-white dark:bg-neutral-900 rounded-3xl flex flex-col group p-2.5">
            <div className="relative flex-shrink-0 ">
              <div className="">
                <NcImage
                  containerClassName="flex block dark:hidden aspect-w-16 aspect-h-9 w-full h-0 rounded-2xl overflow-hidden z-0"
                  src="https://res.cloudinary.com/newlink/image/upload/v1691881809/Landing%20Page%20Images/hero_dark_qmqs61.png"
                  className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
                  alt="light"
                />
                <NcImage
                  containerClassName="flex hidden dark:block aspect-w-16 aspect-h-9 w-full h-0 rounded-2xl overflow-hidden z-0"
                  src="https://res.cloudinary.com/newlink/image/upload/v1691881809/Landing%20Page%20Images/hero_light_pykfvl.png"
                  className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
                  alt="dark"
                />
              </div>

              {/**<ButtonSecondary
                onClick={() => setShowModal(true)}
                className={`absolute animate-bounce bottom-14 left-2 ${
                  showModal === true ? "hidden" : ""
                }`}
              >
                {showModal === true ? "" : "Watch a demo"}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 ml-2 animate-pulse"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                  />
                </svg>
              </ButtonSecondary>*/}
            </div>
          </div>
        </div>
      </div>

      <NcModal
        renderTrigger={() => null}
        isOpenProp={showModal}
        renderContent={renderContent}
        contentExtraClass="max-w-[1000px]"
        onCloseModal={() => setShowModal(false)}
        modalTitle="Build a Professional Rate Card in Under 10 mins 🎉"
      />
    </div>
  );
};

export default SectionHero2;