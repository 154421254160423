import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import SiteHeader from "containers/SiteHeader";
import PageHome2 from "containers/PageHome/PageHome2";
import PrivacyPolicy from "containers/TermsAndPrivacy/PrivacyPolicy";
import Terms from "containers/TermsAndPrivacy/Terms";
import PageSurvey from "containers/PageSurvey";
import CookieBanner from "containers/Cookies/CookieBanner";
import CookiePolicy from "containers/Cookies/CookiePolicy";
import Success from "containers/StripePages/Success";
import Failed from "containers/StripePages/Failed";
import Share from "containers/Page404/Share";
import FindCreators from "containers/Page404/FindCreators";
import CreateInfluencerPage from "containers/SubscriptionFlow/CreateInfluencerPage";
import Verify from "containers/SubscriptionFlow/Verify";
import Verified from "containers/SubscriptionFlow/Verified";
import PaymentNotification from "containers/FlutterwavePage/PaymentNotification";
import RefundPolicy from "containers/TermsAndPrivacy/RefundPolicy";
import PageRefund from "containers/PageContact/PageRefund";
import RateCardVideo from "containers/BlogPage/RateCardVideo";
import PageBook from "containers/CreatorInvoice/PageBook";
import PayInvoice from "containers/CreatorInvoice/PayInvoice";
import PaymentComplete from "containers/PaymentComplete";
import InfluencerDetailPage from "containers/InfluencerDetails/InfluencerDetailPage";
import Pricing from "containers/PageSubcription/Pricing";
import FAQAccordion from "containers/FaqAccordion/FAQAccordion";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome2 },
  { path: "/#", exact: true, component: PageHome2 },

  { path: "/share/:searchName", component: Share },
  { path: "/creators", component: FindCreators },

  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/pricing", component: Pricing },
  { path: "/privacy", component: PrivacyPolicy },
  { path: "/terms", component: Terms },
  { path: "/cookies", component: CookiePolicy },
  { path: "/refund-policy", component: RefundPolicy },
  { path: "/refund", component: PageRefund },
  { path: "/faqs", component: FAQAccordion },
  { path: "/survey", component: PageSurvey },

  //-----------------------------------------------------------------//
  //Booking & Invoice Payments
  { path: "/deliverable/:username/:deliverableId", component: PageBook },
  { path: "/pay/:username/:invoiceId", component: PayInvoice },
  { path: "/confirmed", component: PaymentComplete },

  //-----------------------------------------------------------------//
  //SUBSCRIPTION FLOW
  { path: "/create/:priceId/:amount", component: CreateInfluencerPage },
  { path: "/verify/:priceId/:amount", component: Verify },
  { path: "/verified/:priceId/:amount", component: Verified },

  //STRIPE PAGES
  //{ path: "/success", component: Success },
  //{ path: "/failed", component: Failed },

  //FLUTTERWAVE
  { path: "/payment-notification", component: PaymentNotification },

  //PROMOTIONAL LINKS
  { path: "/influencer-rate-card", component: RateCardVideo },
];

const Routes = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <ScrollToTop />
        <SiteHeader />
        <Switch>
          {pages.map(({ component, path, exact }) => {
            return (
              <Route
                key={path}
                component={component}
                exact={!!exact}
                path={path}
              />
            );
          })}
          <Route exact path="/:username" component={InfluencerDetailPage} />

          <Route component={Page404} />
        </Switch>
        <Footer />
        <CookieBanner />
      </BrowserRouter>
    </>
  );
};

export default Routes;