// @ts-nocheck
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import SiteHeader404 from "containers/SiteHeader404";
import Footer404 from "shared/Footer/Footer404";

export interface PaymentNotificationProps {
  className?: string;
}

const PaymentNotification: FC<PaymentNotificationProps> = ({
  className = "",
}) => {
  return (
    <>
      <SiteHeader404 />
      <div
        className={`nc-PaymentNotification pt-20 pb-72 ${className}`}
        data-nc-id="PaymentNotification"
      >
        <Helmet>
          <title>Payment Notification</title>
        </Helmet>
        <div className="container">
          <div className="max-w-3xl mx-auto my-12 space-y-10 sm:lg:my-16 lg:my-24 sm:space-y-10">
            <div className="mt-10">
              <div className="">
                <div className="w-full max-w-lg px-6 pt-10 mx-auto border-2 shadow-xl pb-9 rounded-2xl">
                  <div className="flex flex-col w-full max-w-md mx-auto space-y-16">
                    <div className="flex flex-col items-center justify-center space-y-2 text-center">
                      <div className="text-3xl font-semibold">
                        <p>Subscription Successful 🎉!</p>
                      </div>
                      <div className="flex flex-row text-sm font-medium text-gray-600 dark:text-gray-400">
                        <p>
                          Hi👋, your subscription to GetCollabo was processed
                          successfully.
                        </p>
                      </div>
                      <svg
                        aria-hidden="true"
                        className="w-24 h-24 text-green-500 dark:text-green-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>

                    <a href="https://app.getcollabo.io/signup" target="_blank">
                      <ButtonPrimary className="w-full">
                        <span>Create your workspace</span>
                        <svg
                          className="w-6 h-6 ml-2"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3 22H21"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </ButtonPrimary>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer404 />
    </>
  );
};

export default PaymentNotification;