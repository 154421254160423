import React from "react";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import { Helmet } from "react-helmet";
import {
  RiFacebookFill,
  RiInstagramFill,
  RiTwitterFill,
  RiLinkedinFill,
} from "react-icons/ri";
import { SiTiktok } from "react-icons/si";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Footer404 from "shared/Footer/Footer404";
import SiteHeader404 from "containers/SiteHeader404";

const RateCardVideo = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="#" color="blue" name="Rate Cards" />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            How to build a professional rate card
          </h1>
          <span className="block pb-1 text-base text-neutral-500 md:text-lg dark:text-neutral-400">
            In this video, you'd learn how to build a professional rate card
            completely for free on GetCollabo
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-row items-center sm:flex-row sm:justify-between">
            <div className="flex-wrap items-center flex-shrink-0 hidden text-sm leading-none text-left xl:flex sm:flex lg:flex md:flex nc-PostMeta2 text-neutral-700 dark:text-neutral-200">
              <Avatar
                imgUrl="https://getcollabo.io/static/media/founder2.35b9a9fe10db50dc9ba7.jpg"
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a
                    className="block font-semibold"
                    href="https://twitter.com/oluwanix"
                  >
                    Shola Jegede
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    Co-founder & CEO, GetCollabo
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-1.5 sm:ml-3">
              <ul className="flex space-x-4 justify-left items-left">
                <li>
                  <a
                    href="https://www.twitter.com/getcollabo"
                    title="Twitter"
                    className="transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                  >
                    <RiTwitterFill className="w-6 h-6 dark:fill-white fill-bg-neutral-900" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/getcollabo"
                    title="Instagram"
                    className="transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                  >
                    <RiInstagramFill className="w-6 h-6 dark:fill-white fill-bg-neutral-900" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.linkedin.com/company/getcollabo"
                    title="LinkedIn"
                    className="transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                  >
                    <RiLinkedinFill className="w-6 h-6 dark:fill-white fill-bg-neutral-900" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.tiktok.com/@getcollabo"
                    title="TikTok"
                    className="transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                  >
                    <SiTiktok className="w-5 h-6 dark:fill-white fill-bg-neutral-900" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.facebook.com/getcollabo"
                    title="Facebook"
                    className="transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                  >
                    <RiFacebookFill className="w-6 h-6 dark:fill-white fill-bg-neutral-900" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto xl:hidden sm:hidden md:hidden lg:hidden">
        <div className="flex nc-SingleAuthor">
          <Avatar
            imgUrl="https://getcollabo.io/static/media/founder2.35b9a9fe10db50dc9ba7.jpg"
            sizeClass="w-11 h-11 md:w-24 md:h-24"
          />
          <div className="flex flex-col max-w-lg ml-3 space-y-1 sm:ml-5">
            <span className="text-xs tracking-wider uppercase text-neutral-400">
              POSTED BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/ncmaz/author/the-demo-author-slug">Shola Jegede</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              Co-founder & CEO, GetCollabo
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <SiteHeader404 />
      <div className="pt-8 nc-PageSingle lg:pt-16 ">
        <Helmet>
          <title>
            How to build a professional influencer/creator rate card
          </title>
        </Helmet>
        {renderHeader()}

        <div className="pt-12 pb-8 pl-4 pr-4 sm:pl-10 sm:pr-10 md:pl-10 md:pr-10 lg:pl-10 lg:pr-10 xl:pl-10 xl:pr-10">
          <iframe
            allowFullScreen
            title="How to build a professional creator/influencer rate card in 2023"
            src="https://www.youtube.com/embed/fiUI0koisHY"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            className="relative w-full xl:h-[506px] md:h-[460px] lg:h-[460px] h-[250px] sm:h-[380px] max-w-4xl mx-auto border border-gray-300 shadow-2xl rounded-3xl dark:border-gray-700 yt-video"
          ></iframe>
        </div>

        <div className="justify-center pl-8 pr-8 text-center">
          <p className="mb-8 text-base text-neutral-500 dark:text-neutral-400">
            Build your professional rate card completely for free in under 10
            mins. Click the button to get started.
          </p>

          <a href="https://app.getcollabo.io/signup" target="_blank">
            <ButtonPrimary>
              <span>Get started for free</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 ml-2 animate-bounce"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </ButtonPrimary>
          </a>
        </div>

        <div className="container mt-6 mb-20 space-y-10 nc-SingleContent">
          {renderAuthor()}
        </div>
      </div>

      <Footer404 />
    </>
  );
};

export default RateCardVideo;