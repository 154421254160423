import React from "react";
import Header2 from "components/Header/Header2";
import { useLocation } from "react-router-dom";

const SiteHeader = () => {
  const location = useLocation();

  const chatRoutes = [
    "/pricing",
    "/about",
    "/contact",
    "/privacy",
    "/refund",
    "/",
    "/#",
    "/share/:searchName",
    "/creators",
    "/terms",
    "/cookies",
    "/refund-policy",
    "/faqs",
    "/survey",
    "/success",
    "/failed",
  ];

  const isChatPage = chatRoutes.some((route) => {
    const currentPath = decodeURIComponent(location.pathname);
    const routeSegments = route.split("/");
    const currentSegments = currentPath.split("/");

    if (routeSegments.length !== currentSegments.length) {
      return false;
    }

    for (let i = 0; i < routeSegments.length; i++) {
      const routeSegment = routeSegments[i];
      const currentSegment = currentSegments[i];

      if (routeSegment.startsWith(":")) {
        continue; // Skip parameter segments
      }

      if (routeSegment !== currentSegment) {
        return false;
      }
    }

    return true;
  });

  return isChatPage ? <Header2 /> : null;
};

export default SiteHeader;